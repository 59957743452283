module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"de","name":"Abschied von Klaus Straube","short_name":"KlausStraube","description":"Gedenkseite von Klaus Straube. Nachrufe, Kondolenzen und Abschied nehmen.","start_url":"/","background_color":"#64a19d","theme_color":"#64a19d","display":"standalone","icon":"src/assets/img/website-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef0b361bcb04e9559e9137a5ea0b4370"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
